import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/notifications');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getQueued() {
        try {
            const response = await axios.get('/notifications/queued');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async notificationUserPending(user) {
        try {
            const response = await axios.put(`/notifications/user/pending/${user}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/notification/new', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async S3ImageUpload(data) {
        try {
            const response = await axios.post('/notification/upload-image', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        /* try {
            const response = await axios.put(`/saletax/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        } */
    },
    async delete(data) {
        try {
            const response = await axios.put(`/notification/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async deleteQueued(data) {
        try {
            const response = await axios.put(`/notification/delete/queued/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async sendQueuedNotifications() {
        try {
            const response = await axios.post(`/notifications/queued/review/daily`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
